import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { publish } from 'javascripts/utils/events';
import Input from './Input';
import Select from './Select';
import userService from 'javascripts/services/user-service';

const newAddressFormSchema = yup.object().shape({
  name: yup.string().trim().required('This field is required.'),
  company: yup.string().trim().nullable(),
  street1: yup.string().trim().required('This field is required.'),
  street2: yup.string().trim().nullable(),
  city: yup.string().trim().required('This field is required.'),
  state: yup.string().required('This field is required.'),
  zip: yup.string()
    .required('This field is required.')
    .matches(/^\d+$/, 'ZIP Code must be a number.')
    .length(5, 'ZIP Code must be 5 digits.'),
  phone: yup.string()
    .when('state', {
      is: (state: string) => userService.isPhoneNumberRequired(state),
      then: yup.string().trim().nullable().required('This field is required.'),
      otherwise: yup.string().nullable(),
    }),
  preferred_shipping_carrier_id: yup.number().required('Please select a preferred shipping carrier.')
});

const defaultState = userService.getDefaultState();
const defaultShippingCarrier = userService.getDefaultShippingCarrier(defaultState) || 1;
const defaultValues = {
  state: defaultState,
  preferred_shipping_carrier_id: defaultShippingCarrier
};

const AddressForm = (props: { address?: AddressFormData, onCreateCancelled?: () => void, onEditCancelled?: () => void }) => {
  const editMode = !!props.address?.id;
  const pickup = !!props.address?.pickup_location;
  const newPickup = !editMode && pickup;
  const editAddress = props.address;

  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const { register, handleSubmit, watch, setValue, getValues, trigger, formState: { errors } } = useForm<AddressFormData>({
    defaultValues: props.address ?? defaultValues,
    resolver: yupResolver(newAddressFormSchema)
  });

  const watchState = watch('state');
  const previousWatchState = React.useRef(watchState);

  const states = userService.getStateOptions();
  const shippingCarriers = userService.getShippingCarrierOptions(watchState);

  const phoneNumberPlaceholder = React.useMemo(() => (
    userService.isPhoneNumberRequired(watchState) ? "" : "Optional, but helps with delivery problems."
  ), [watchState])

  // reset shipping carrier when state changes
  useEffect(() => {
  if (previousWatchState.current !== watchState) {
      const shippingCarrier = userService.getDefaultShippingCarrier(watchState);
      if (shippingCarrier) setValue('preferred_shipping_carrier_id', shippingCarrier);
      previousWatchState.current = watchState;
    }
  }, [watchState, shippingCarriers]);

  const injectAddress = (address: AddressFormData) => {
    const root = document.querySelector("[data-modal=verify-address]");
    if (!root) return console.error("Unable to find modal 'verify-address'.");

    const addressHolder = root.querySelector('#address-holder');
    if (addressHolder) {
      addressHolder.setAttribute('data-reference-address', JSON.stringify({
        street1: address.street1,
        street2: address.street2,
        city: address.city,
        state: address.state,
        zip: address.zip,
      }));
      const event = new CustomEvent('addressInjected');
      addressHolder.dispatchEvent(event);
    }
  };

  const injectAddressCandidates = (addressCandidates: AddressData[]) => {
    const root = document.querySelector("[data-modal=verify-address]");
    if (!root) return console.error("Unable to find modal 'verify-address'.");

    const addressHolder = root.querySelector('#address-holder');
    if (addressHolder) {
      addressHolder.setAttribute('data-address-candidates', JSON.stringify(addressCandidates));
      const event = new CustomEvent('addressCandidatesInjected');
      addressHolder.dispatchEvent(event);
    }
  };

  const verifyAddress = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    setLoading(true);
    setServerErrors([]);
    const address = getValues();
    const response = await userService.verifyAddress(address);
    if (response.result === 'error') {
      setServerErrors(response.errors);
    } else {
      setServerErrors([]);
      publish('OPEN_MODAL', 'verify-address');
      injectAddress(address);
      injectAddressCandidates(response.addresses || []);
    }
    setLoading(false);
  }

  // handle create/edit submissions
  const saveAddress = async (address: AddressFormData) => {
    setLoading(true);
    setServerErrors([]);
    const response = editMode ? await userService.editAddress(address) : await userService.createAddress(address, true);
    if (response.result === 'error') {
      setServerErrors(response.errors);
    } else {
      setServerErrors([]);
      publish('CHECKOUT_ADDRESS_SELECTION_REQUESTED', response.address);
      publish('CHECKOUT_UPDATE_USER_DETAILS');
      newPickup && publish('CLOSE_MODAL', 'carrier-hold-locations');
      publish('CLOSE_MODAL', 'address-management');
      if (props.onEditCancelled) props.onEditCancelled();
    }
    setLoading(false);
  }

  const onSubmit = (address: AddressFormData) => {
    if (pickup) {
      saveAddress(address);
    } else {
      verifyAddress();
    }
  }

  useEffect(() => {
    const addressCandidateHolder = document.getElementById('address-candidate-holder');
    if (addressCandidateHolder) {
      const handleAddressCandidateChosen = () => {
        const data = addressCandidateHolder.getAttribute('data-address-candidate');

        if (!data) return;

        const parsedAddress = JSON.parse(data);
        setValue('street1', parsedAddress.street1);
        setValue('street2', parsedAddress.street2);
        setValue('city', parsedAddress.city);
        setValue('state', parsedAddress.state);
        setValue('zip', parsedAddress.zip);

        addressCandidateHolder.removeAttribute('data-address-candidate');

        saveAddress(getValues());
      };

      addressCandidateHolder.addEventListener('addressCandidateChosen', handleAddressCandidateChosen);

      return () => {
        addressCandidateHolder.removeEventListener('addressCandidateChosen', handleAddressCandidateChosen);
      };
    }
  }, []);

  useEffect(() => {
    const root = document.querySelector("[data-modal=address-management]");
    if (root) {
      const handleKeepAddressAsEntered = () => {
        saveAddress(getValues());
      };

      root.addEventListener('keepAddressAsEntered', handleKeepAddressAsEntered);

      return () => {
        root.removeEventListener('keepAddressAsEntered', handleKeepAddressAsEntered);
      };
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header sticky">
        <div className="title">
          { editMode ? `Editing Address for ${editAddress?.name}` : 'New Address' }
        </div>
        <button type="button" className="modal-toggle" data-modal-toggle>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      { editMode ?
        <div className="ui-message edit-message warning">
          <p>You are currently editing address details for <strong>{editAddress?.name}</strong>.</p>
          <p>To cancel editing, please click the <strong>Cancel Edit</strong> button below.</p>
        </div>
      : null }
      <div className="modal-body">
        <div className="ui-form">
          <Input label="Name" id="address-name" name="name" register={register} error={errors.name?.message} placeholder="Agent Red" maxLength={40} />
          <Input disabled={pickup} label="Company" id="address-company" name="company" register={register} error={errors.company?.message} placeholder="Optional" maxLength={50} />
          <div className="grid-row">
            <div className="col-12 col-xs-6">
              <Input disabled={pickup} label="Street Address" id="address-street1" name="street1" register={register} error={errors.street1?.message} maxLength={50} />
            </div>
            <div className="col-12 col-xs-6">
              <Input disabled={pickup} label="Apt/Suite" id="address-street2" name="street2" register={register} error={errors.street2?.message} placeholder="Optional" maxLength={50} />
            </div>
          </div>
          <div className="grid-row">
            <div className="col-12 col-sm-5">
              <Input disabled={pickup} label="City" id="address-city" name="city" register={register} error={errors.city?.message} minLength={3} maxLength={50} />
            </div>
            <div className="col-12 col-xs-8 col-sm-4">
              <Select disabled={pickup} options={states} label="State" id="address-state" name="state" register={register} error={errors.state?.message} />
            </div>
            <div className="col-12 col-xs-4 col-sm-3">
              <Input disabled={pickup} label="ZIP Code" id="address-zip-code" name="zip" register={register} error={errors.zip?.message} placeholder="00000" maxLength={5} />
            </div>
          </div>
          <div className="grid-row">
            <div className="col-12 col-xs-6">
              <Input label="Phone Number" id="address-phone" name="phone" register={register} error={errors.phone?.message} placeholder={phoneNumberPlaceholder} maxLength={25} />
            </div>
            <div className="col-12 col-xs-6">
              <Select disabled={pickup} options={shippingCarriers} label="Preferred Carrier" id="address-shipping-carrier" name="preferred_shipping_carrier_id" register={register} error={errors.preferred_shipping_carrier_id?.message} />
            </div>
          </div>

          { serverErrors.length > 0 ?
            <div className="ui-message error">
              <p>Please resolve the following errors:</p>
              <ul>
                { serverErrors.map(error => (
                  <li key={error}>
                    <strong>{error}</strong>
                  </li>
                )) }
              </ul>
            </div>
          : null }
        </div>
      </div>
      <div className="modal-footer sticky">
        <div className="grid-row">
          <div className="col-auto">
            { editMode ?
              <button type="button" className="ui-button secondary" onClick={props.onEditCancelled}>Cancel Edit</button>
            : props.onCreateCancelled ?
              <button type="button" className="ui-button secondary" onClick={props.onCreateCancelled}>Cancel</button>
            :
              <button type="button" className="ui-button secondary" data-modal-toggle>Dismiss</button>
            }
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <button type="submit" className={`ui-button ${ loading ? 'loading' : '' }`}>
              { editMode ?
                <>
                  <span className="text h-to-xs">Save Changes</span>
                  <span className="text h-from-sm">Save</span>
                </>
              :
                <>
                  <span className="text h-to-xs">Save and Use Address</span>
                  <span className="text h-from-sm">Save</span>
                </>
              }
            </button>
          </div>
        </div>
      </div>
      <div className='hidden' id='address-candidate-holder'></div>
    </form>
  );
}

export default AddressForm;
